var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "480",
            "max-height": "400",
            persistent: "",
            fullscreen: _vm.$vuetify.breakpoint.smAndDown,
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "overflow-hidden" },
            [
              _c(
                "v-row",
                { staticClass: "ma-0 ml-4" },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "col-10 px-0 grey--text text--darken-4 text-h6 text-truncate",
                    },
                    [_vm._v(_vm._s(_vm.$t("repeatTicket")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "col-2 my-auto",
                      attrs: { "aria-label": "cross", icon: "" },
                      on: {
                        click: function ($event) {
                          return _vm.closeDialog()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [_c("v-divider")],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "mx-4 mt-4",
                  class: _vm.reasonErrors.length === 0 ? "mb-6" : "mb-2",
                  attrs: { "no-gutters": "", justify: "center" },
                },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.reasonList,
                      "menu-props": { bottom: true, offsetY: true },
                      outlined: "",
                      dense: "",
                      required: "",
                      "hide-details": _vm.reasonErrors.length === 0,
                      "error-messages": _vm.reasonErrors,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.reason.$touch()
                      },
                      blur: function ($event) {
                        return _vm.$v.reason.$touch()
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "red--text" }, [
                              _vm._v(" *"),
                            ]),
                            _vm._v(" " + _vm._s(_vm.$t("reason")) + " "),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "item",
                        fn: function (data) {
                          return [
                            _vm._v(
                              " " + _vm._s(_vm.getReasonText(data.item)) + " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "selection",
                        fn: function (ref) {
                          var item = ref.item
                          return [
                            _vm._v(" " + _vm._s(_vm.getReasonText(item)) + " "),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.reason,
                      callback: function ($$v) {
                        _vm.reason = $$v
                      },
                      expression: "reason",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "mx-4 mt-2",
                  class: _vm.scheduleTimeError.length === 0 ? "mb-6" : "mb-0",
                  attrs: { "no-gutters": "", justify: "center" },
                },
                [
                  _c(
                    "v-menu",
                    {
                      ref: "menu1",
                      attrs: {
                        "close-on-content-click": false,
                        transition: "scale-transition",
                        "offset-y": "",
                        "max-width": "290px",
                        "min-width": "auto",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        "persistent-hint": "",
                                        outlined: "",
                                        dense: "",
                                        "prepend-inner-icon": "mdi-calendar",
                                        "error-messages":
                                          _vm.dateFormattedErrors,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.$v.work_date.$touch()
                                        },
                                        blur: function ($event) {
                                          return _vm.$v.work_date.$touch()
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("WorkDate")) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: _vm.work_date,
                                        callback: function ($$v) {
                                          _vm.work_date = $$v
                                        },
                                        expression: "work_date",
                                      },
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.menu1,
                        callback: function ($$v) {
                          _vm.menu1 = $$v
                        },
                        expression: "menu1",
                      },
                    },
                    [
                      _c("v-date-picker", {
                        attrs: {
                          "no-title": "",
                          min: _vm.getNowDate,
                          max: _vm.getEndDate,
                          "allowed-dates": _vm.allowedDates,
                        },
                        on: {
                          input: function ($event) {
                            _vm.menu1 = false
                          },
                        },
                        model: {
                          value: _vm.date,
                          callback: function ($$v) {
                            _vm.date = $$v
                          },
                          expression: "date",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "mx-4 mt-2",
                  class: _vm.contactErrors.length === 0 ? "mb-6" : "mb-0",
                  attrs: { "no-gutters": "", justify: "center" },
                },
                [
                  _c(
                    "v-menu",
                    {
                      ref: "menu",
                      attrs: {
                        "nudge-right": 40,
                        "close-on-content-click": false,
                        "return-value": _vm.time,
                        transition: "scale-transition",
                        "offset-y": "",
                        "max-width": "290px",
                        "min-width": "290",
                      },
                      on: {
                        "update:returnValue": function ($event) {
                          _vm.time = $event
                        },
                        "update:return-value": function ($event) {
                          _vm.time = $event
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        label: _vm.$t("time"),
                                        format: "ampm",
                                        "prepend-inner-icon":
                                          "mdi-clock-time-four-outline",
                                        "persistent-hint": "",
                                        outlined: "",
                                        dense: "",
                                        "error-messages":
                                          _vm.dateFormattedErrors,
                                        readonly: "",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.$v.scheduleTime.$touch()
                                        },
                                        blur: function ($event) {
                                          return _vm.$v.scheduleTime.$touch()
                                        },
                                        change: _vm.updateTimeFormat,
                                      },
                                      model: {
                                        value: _vm.scheduleTime,
                                        callback: function ($$v) {
                                          _vm.scheduleTime = $$v
                                        },
                                        expression: "scheduleTime",
                                      },
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.menu2,
                        callback: function ($$v) {
                          _vm.menu2 = $$v
                        },
                        expression: "menu2",
                      },
                    },
                    [
                      _vm.menu2
                        ? _c(
                            "v-time-picker",
                            {
                              attrs: {
                                "menu-props": { bottom: true, offsetY: true },
                                "full-width": "",
                              },
                              model: {
                                value: _vm.time,
                                callback: function ($$v) {
                                  _vm.time = $$v
                                },
                                expression: "time",
                              },
                            },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      _vm.menu2 = false
                                    },
                                  },
                                },
                                [_vm._v(" Cancel ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$refs.menu.save(_vm.time)
                                    },
                                  },
                                },
                                [_vm._v(" OK ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "mx-4 mt-2",
                  class: _vm.contactPhoneErrors.length === 0 ? "mb-6" : "mb-0",
                  attrs: { "no-gutters": "", justify: "center" },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      required: "",
                      "hide-details": _vm.contactErrors.length === 0,
                      "error-messages": _vm.contactErrors,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.contact.$touch()
                      },
                      blur: function ($event) {
                        return _vm.$v.contact.$touch()
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _vm._v(" " + _vm._s(_vm.$t("contactName")) + " "),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.contact,
                      callback: function ($$v) {
                        _vm.contact = $$v
                      },
                      expression: "contact",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "mx-4 mt-2",
                  class: _vm.contactPhoneErrors.length === 0 ? "mb-6" : "mb-0",
                  attrs: { "no-gutters": "", justify: "center" },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("contactPhone"),
                      outlined: "",
                      dense: "",
                      required: "",
                      "hide-details": _vm.contactPhoneErrors.length === 0,
                      "error-messages": _vm.contactPhoneErrors,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.contact_phone.$touch()
                      },
                      blur: function ($event) {
                        return _vm.$v.contact_phone.$touch()
                      },
                    },
                    model: {
                      value: _vm.contact_phone,
                      callback: function ($$v) {
                        _vm.contact_phone = $$v
                      },
                      expression: "contact_phone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "mx-4 mt-2 mb-2",
                  attrs: { "no-gutters": "", justify: "center" },
                },
                [
                  _c("v-textarea", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      required: "",
                      rows: "2",
                      label: _vm.$t("comments"),
                      "error-messages": _vm.commentsErrors,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.comments.$touch()
                      },
                      blur: function ($event) {
                        return _vm.$v.comments.$touch()
                      },
                    },
                    model: {
                      value: _vm.comments,
                      callback: function ($$v) {
                        _vm.comments = $$v
                      },
                      expression: "comments",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "mx-4 mt-2",
                  attrs: { "no-gutters": "", justify: "center" },
                },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      items: _vm.membersToNotifyList,
                      "menu-props": { bottom: true, offsetY: true },
                      label: _vm.$t("membersToNotify"),
                      outlined: "",
                      dense: "",
                      required: "",
                      multiple: "",
                      "item-text": "mbcode_name",
                      "item-value": "mbcode",
                      "search-input": _vm.searchMembers,
                    },
                    on: {
                      input: function ($event) {
                        _vm.searchMembers = null
                      },
                      "update:searchInput": function ($event) {
                        _vm.searchMembers = $event
                      },
                      "update:search-input": function ($event) {
                        _vm.searchMembers = $event
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "prepend-item",
                        fn: function () {
                          return [
                            _c(
                              "v-list-item",
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    "prepend-inner-icon": "mdi-magnify",
                                    label: _vm.$t("searchMembersToNotify"),
                                    "single-line": "",
                                    "hide-details": "",
                                    flat: "",
                                    dense: "",
                                    variant: "underlined",
                                    "clear-icon": "mdi-close-circle",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchMembers,
                                    callback: function ($$v) {
                                      _vm.searchMembers = $$v
                                    },
                                    expression: "searchMembers",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "selection",
                        fn: function (ref) {
                          var attrs = ref.attrs
                          var item = ref.item
                          var parent = ref.parent
                          var selected = ref.selected
                          return [
                            _c(
                              "v-chip",
                              _vm._b(
                                {
                                  attrs: {
                                    size: "small",
                                    density: "compact",
                                    "input-value": selected,
                                  },
                                },
                                "v-chip",
                                attrs,
                                false
                              ),
                              [
                                _c(
                                  "span",
                                  { staticClass: "pr-2 chip-content" },
                                  [_vm._v(" " + _vm._s(item.mbcode_name) + " ")]
                                ),
                                _c(
                                  "v-icon",
                                  {
                                    attrs: { small: "" },
                                    on: {
                                      click: function ($event) {
                                        return parent.selectItem(item)
                                      },
                                    },
                                  },
                                  [_vm._v(" mdi-close-circle ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.selectedMembersToNotify,
                      callback: function ($$v) {
                        _vm.selectedMembersToNotify = $$v
                      },
                      expression: "selectedMembersToNotify",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "mx-4 mt-2",
                  class: _vm.$vuetify.breakpoint.smAndDown ? "mb-16" : "",
                  attrs: { "no-gutters": "", justify: "center" },
                },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      items: _vm.membersToAddList,
                      "menu-props": { bottom: true, offsetY: true },
                      label: _vm.$t("membersToAdd"),
                      outlined: "",
                      dense: "",
                      required: "",
                      multiple: "",
                      "item-text": "mbcode_name",
                      "item-value": "mbcode",
                      "error-messages": _vm.membersToAddError,
                      "search-input": _vm.searchCountyMembers,
                    },
                    on: {
                      change: _vm.updateMemberCode,
                      input: function ($event) {
                        return _vm.$v.membersToAddListString.$touch()
                      },
                      blur: function ($event) {
                        return _vm.$v.membersToAddListString.$touch()
                      },
                      "update:searchInput": function ($event) {
                        _vm.searchCountyMembers = $event
                      },
                      "update:search-input": function ($event) {
                        _vm.searchCountyMembers = $event
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "prepend-item",
                        fn: function () {
                          return [
                            _c(
                              "v-list-item",
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    "prepend-inner-icon": "mdi-magnify",
                                    label: _vm.$t("searchMembersToAdd"),
                                    "single-line": "",
                                    "hide-details": "",
                                    flat: "",
                                    dense: "",
                                    variant: "underlined",
                                    "clear-icon": "mdi-close-circle",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchCountyMembers,
                                    callback: function ($$v) {
                                      _vm.searchCountyMembers = $$v
                                    },
                                    expression: "searchCountyMembers",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "selection",
                        fn: function (ref) {
                          var attrs = ref.attrs
                          var item = ref.item
                          var parent = ref.parent
                          var selected = ref.selected
                          return [
                            _c(
                              "v-chip",
                              _vm._b(
                                {
                                  attrs: {
                                    size: "small",
                                    density: "compact",
                                    "input-value": selected,
                                  },
                                },
                                "v-chip",
                                attrs,
                                false
                              ),
                              [
                                _c(
                                  "span",
                                  { staticClass: "pr-2 chip-content" },
                                  [_vm._v(" " + _vm._s(item.mbcode_name) + " ")]
                                ),
                                _c(
                                  "v-icon",
                                  {
                                    attrs: { small: "" },
                                    on: {
                                      click: function ($event) {
                                        return parent.selectItem(item)
                                      },
                                    },
                                  },
                                  [_vm._v(" mdi-close-circle ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.selectedMembersToAdd,
                      callback: function ($$v) {
                        _vm.selectedMembersToAdd = $$v
                      },
                      expression: "selectedMembersToAdd",
                    },
                  }),
                ],
                1
              ),
              !_vm.$vuetify.breakpoint.smAndDown
                ? _c("v-row", { staticClass: "mb-2" }, [_c("v-divider")], 1)
                : _vm._e(),
              !_vm.$vuetify.breakpoint.smAndDown
                ? _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-none rounded-lg elevation-0",
                          attrs: {
                            color: "primary",
                            outlined: "",
                            depressed: "",
                            width: "90",
                          },
                          on: { click: _vm.closeDialog },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "primary text-none rounded-lg elevation-0",
                          attrs: {
                            width: "90",
                            depressed: "",
                            disabled: _vm.$v.$invalid,
                          },
                          on: { click: _vm.retransmitTicketSubmit },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("submit")) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$vuetify.breakpoint.smAndDown
                ? _c(
                    "v-bottom-navigation",
                    {
                      staticClass: "px-2 py-4",
                      attrs: { fixed: "", horizontal: "", height: "70" },
                    },
                    [
                      _c(
                        "v-row",
                        {
                          attrs: { justify: "space-between", "no-gutters": "" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "mr-2 text-none rounded-lg elevation-0",
                              attrs: {
                                color: "primary",
                                outlined: "",
                                width: "50%",
                              },
                              on: { click: _vm.closeDialog },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "text-none font-weight-regular grey--text rounded-lg elevation-0",
                              attrs: {
                                color: "primary",
                                width: "50%",
                                depressed: "",
                                disabled: _vm.$v.$invalid,
                              },
                              on: { click: _vm.retransmitTicketSubmit },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "white--text text-none" },
                                [_vm._v(_vm._s(_vm.$t("submit")))]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }