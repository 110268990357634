var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$vuetify.breakpoint.mdAndUp
        ? _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  staticClass:
                    "text-none rounded-lg font-weight-regular mt-2 white--text mr-4",
                  attrs: {
                    color: "#1E853A",
                    width: "115",
                    disabled: !_vm.updateOH,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.updateTicketDetails("UpdateTicket")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("update")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "text-none rounded-lg font-weight-regular mt-2 white--text mr-4",
                  attrs: {
                    color: "#1E853A",
                    width: "140",
                    disabled: !_vm.repeatOH,
                  },
                  on: { click: _vm.retransmitTicket },
                },
                [_vm._v(_vm._s(_vm.$t("repeat")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "text-none rounded-lg font-weight-regular mt-2 white--text mr-4",
                  attrs: {
                    color: "#1E853A",
                    width: "115",
                    disabled: !_vm.diginOH,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.updateTicketDetails("Digin")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("digin")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "text-none rounded-lg font-weight-regular mt-2 white--text",
                  attrs: {
                    color: "#1E853A",
                    width: "115",
                    disabled: !_vm.cancelOH,
                  },
                  on: { click: _vm.cancelTicket },
                },
                [_vm._v(_vm._s(_vm.$t("cancel")))]
              ),
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  staticClass:
                    "text-none rounded-lg font-weight-regular mt-2 white--text mr-4",
                  attrs: {
                    color: "#1E853A",
                    width: "115",
                    disabled: !_vm.updateOH,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.updateTicketDetails("UpdateTicket")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("update")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "text-none rounded-lg font-weight-regular mt-2 white--text mr-4",
                  attrs: {
                    color: "#1E853A",
                    width: "140",
                    disabled: !_vm.repeatOH,
                  },
                  on: { click: _vm.retransmitTicket },
                },
                [_vm._v(_vm._s(_vm.$t("repeat")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "text-none rounded-lg font-weight-regular mt-2 white--text mr-4",
                  attrs: {
                    color: "#1E853A",
                    width: "115",
                    disabled: !_vm.diginOH,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.updateTicketDetails("Digin")
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("digin")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "text-none rounded-lg font-weight-regular mt-2 white--text",
                  attrs: {
                    color: "#1E853A",
                    width: "115",
                    disabled: !_vm.cancelOH,
                  },
                  on: { click: _vm.cancelTicket },
                },
                [_vm._v(_vm._s(_vm.$t("cancel")))]
              ),
            ],
            1
          ),
      _vm.cancelTicketDialog
        ? _c("CancelTicketDialogOH", {
            attrs: {
              "ticket-data": _vm.ticketDetails,
              dialog: _vm.cancelTicketDialog,
            },
            on: {
              cancelTicketSubmit: _vm.cancelTicketSubmit,
              closeDialog: _vm.closeCancelTicketDialog,
              cancelTicketSuccess: _vm.cancelTicketSuccess,
              errorSubmitTicket: _vm.errorSubmitTicket,
            },
          })
        : _vm._e(),
      _vm.repeatTicketDialog
        ? _c("RepeatTicketDialogOH", {
            attrs: {
              "ticket-data": _vm.ticketDetails,
              dialog: _vm.repeatTicketDialog,
            },
            on: {
              retransmitTicketSubmit: _vm.retransmitTicketSubmit,
              closeRetransmitDialog: _vm.closeRepeatTicketDialog,
              repeatTicketSuccess: _vm.repeatTicketSuccess,
              errorSubmitTicket: _vm.errorSubmitTicket,
            },
          })
        : _vm._e(),
      _vm.isError
        ? _c("ErrorMessage", {
            attrs: {
              "error-code": _vm.errorCode,
              "error-message": _vm.errorMessage,
            },
            on: {
              ticketScreen: function ($event) {
                _vm.isError = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }