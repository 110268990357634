<template>
  <div>
    <div v-if="$vuetify.breakpoint.mdAndUp">
      <v-btn
        color="#1E853A"
        width="115"
        :disabled="!updateOH"
        @click="updateTicketDetails('UpdateTicket')"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("update") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="140"
        :disabled="!repeatOH"
        @click="retransmitTicket"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("repeat") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="115"
        @click="updateTicketDetails('Digin')"
        :disabled="!diginOH"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("digin") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="115"
        class="text-none rounded-lg font-weight-regular mt-2 white--text"
        :disabled="!cancelOH"
        @click="cancelTicket"
        >{{ $t("cancel") }}</v-btn
      >
    </div>
    <div v-else>
      <v-btn
        color="#1E853A"
        width="115"
        :disabled="!updateOH"
        @click="updateTicketDetails('UpdateTicket')"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("update") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="140"
        :disabled="!repeatOH"
        @click="retransmitTicket"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("repeat") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="115"
        :disabled="!diginOH"
        @click="updateTicketDetails('Digin')"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
      >
        {{ $t("digin") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="115"
        class="text-none rounded-lg font-weight-regular mt-2 white--text"
        :disabled="!cancelOH"
        @click="cancelTicket"
        >{{ $t("cancel") }}</v-btn
      >
    </div>

    <CancelTicketDialogOH
      v-if="cancelTicketDialog"
      :ticket-data="ticketDetails"
      :dialog="cancelTicketDialog"
      @cancelTicketSubmit="cancelTicketSubmit"
      @closeDialog="closeCancelTicketDialog"
      @cancelTicketSuccess="cancelTicketSuccess"
      @errorSubmitTicket="errorSubmitTicket"
    />
    <RepeatTicketDialogOH
      v-if="repeatTicketDialog"
      :ticket-data="ticketDetails"
      :dialog="repeatTicketDialog"
      @retransmitTicketSubmit="retransmitTicketSubmit"
      @closeRetransmitDialog="closeRepeatTicketDialog"
      @repeatTicketSuccess="repeatTicketSuccess"
      @errorSubmitTicket="errorSubmitTicket"
    />
    <ErrorMessage
      v-if="isError"
      :error-code="errorCode"
      :error-message="errorMessage"
      @ticketScreen="isError = false"
    />
  </div>
</template>

<script>
import moment from "moment";
import EditTicket from "@/mixins/EditTicket";
import TicketSecondaryFunctions from "@/mixins/TicketSecondaryFunctions";
import CancelTicketDialogOH from "./CancelTicketDialogOH.vue";
import RepeatTicketDialogOH from "./RepeatTicketDialogOH.vue";
import ErrorMessage from "../../../../molecules/ErrorMessage.vue";

export default {
  mixins: [EditTicket, TicketSecondaryFunctions],
  name: "TickDetails",
  components: {
    CancelTicketDialogOH: () => import("./CancelTicketDialogOH.vue"),
    RepeatTicketDialogOH: () => import("./RepeatTicketDialogOH.vue"),
    ErrorMessage: () => import("../../../../molecules/ErrorMessage.vue"),
  },
  props: {
    ticketDetails: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    cancelOH: false,
    updateOH: false,
    repeatOH: false,
    diginOH: false,
    currentTab: "Tasks",
    dialog: true,
    cancelTicketDialog: false,
    repeatTicketDialog: false,
    hourNoticeTicketDialog: false,
    isViewProjectDetails: false,
    addProjectDialog: false,
    isUploadFiles: false,
    ticketData: {},
    viewExpantionPanel: false,
    errorCode: "",
    errorMessage: "",
    isError: false,
  }),
  computed: {},
  async created() {
    this.moment = moment;
  },
  async mounted() {
    this.ticketData = Object.assign({}, this.ticketDetails);
    await this.checkSecondaryFunctionsEligibility();
  },
  methods: {
    errorSubmitTicket(data) {
      this.errorCode = data.errorCode.toString();
      this.errorMessage = data.error;
      this.isError = true;
      console.log(data);
    },
    retransmitTicket() {
      this.repeatTicketDialog = true;
    },
    hourNoticeTicket() {
      this.hourNoticeTicketDialog = true;
    },
    closeHourNoticeTicketDialog() {
      this.hourNoticeTicketDialog = false;
    },
    hourNoticeTicketSubmit() {
      this.hourNoticeTicketDialog = false;
    },
    closeRepeatTicketDialog() {
      this.repeatTicketDialog = false;
    },
    retransmitTicketSubmit() {
      this.repeatTicketDialog = false;
    },
    async repeatTicketSuccess(
      retransmitTicketNumber,
      retransmitTicketRevision
    ) {
      this.repeatTicketDialog = false;
      this.$emit(
        "repeatTicketSuccess",
        retransmitTicketNumber,
        retransmitTicketRevision
      );
    },
    async updateTicketDetails(editType) {
      await this.updateTicket(this.ticketDetails, editType);
      this.$router.push("/createTicket").catch();
    },
    cancelTicket() {
      this.cancelTicketDialog = true;
    },
    closeCancelTicketDialog() {
      this.cancelTicketDialog = false;
    },
    cancelTicketSubmit() {
      this.cancelTicketDialog = false;
    },
    async cancelTicketSuccess(cancelTicketNumber, cancelTicketRevision) {
      this.cancelTicketDialog = false;
      this.$emit(
        "cancelTicketSuccess",
        cancelTicketNumber,
        cancelTicketRevision
      );
    },

    getNextWorkDay(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
  },
};
</script>

<style lang="scss" scoped></style>
